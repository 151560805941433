<template>
  <div class="animated fadeIn">
    <b-card>
      <h4><i class="fa fa-search"></i> Search Invoice</h4>
      <hr />
      <b-collapse v-model="searchContainer" id="searchCollapse">
        <b-row class="mb-2">
          <b-col md="4">
            <label for="">RTNest Invoice Ref. Number</label>

            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                name="tracking"
                v-model="invnum"
                class="form-control"
                placeholder="RTNest Invoice Ref. Number"
              />
            </b-input-group>
          </b-col>
          <div class="col-md-4">
            <label for="">Project</label>
            <select
              class="select-account form-control"
              v-model="projectidSearch"
            >
              <option
                v-for="option in projectData"
                v-bind:value="option.projectid"
              >
                {{ option.projectname }}
              </option>
            </select>
          </div>
          <b-col md="4">
            <label for="">Vendor Company Name</label>
            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                name="vendor"
                v-model="vendor_company_name"
                class="form-control"
                placeholder="Vendor Company Name"
              />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <label for="">AFE / PO Number</label>
            <b-input-group class="mb-2">
              <b-input
                type="text"
                name="afe"
                v-model="afe"
                class="form-control"
                placeholder="AFE Number"
              />
            </b-input-group>
          </b-col>
          <b-col md="3">
            <label for="">Cost Code #1 / Major</label>
            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                name="cc1"
                v-model="cc1"
                class="form-control"
                placeholder="Cost Code 1"
              />
            </b-input-group>
          </b-col>
          <b-col md="3">
            <label for="">Cost Code #2 / Minor</label>
            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                name="cc2"
                v-model="cc2"
                class="form-control"
                placeholder="Cost Code 2"
              />
            </b-input-group>
          </b-col>
          <b-col md="3">
            <label for="">Cost Code #3 / Description</label>
            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                name="cc3"
                v-model="cc3"
                class="form-control"
                placeholder="Cost Code 3"
              />
            </b-input-group>
          </b-col>
          <b-col md="4">
            <label for="">Invoice Starting Date - Today</label>
            <b-input-group class="mb-2">
              <datepicker
                class="date-picker rt-datepicker"
                v-model="date"
                placeholder="Start Date"
              ></datepicker>
            </b-input-group>
          </b-col>
          <b-col md="6">
            <label for="">Search</label>
            <b-input-group class="mb-2">
              <b-button @click="search()" variant="success" class="mr-3">
                Search <i class="fa fa-search"></i>
              </b-button>
              <b-button @click="clear()" variant="danger">
                Clear <i class="fa fa-times"></i>
              </b-button>
            </b-input-group>
          </b-col>
        </b-row>
      </b-collapse>
      <b-btn v-b-toggle.searchCollapse class="mt-1 btn-blue">
        <span class="when-opened"
          >Close Advanced Search <i class="fa fa-chevron-up"></i
        ></span>
        <span class="when-closed"
          >Open Advanced Search <i class="fa fa-chevron-down"></i
        ></span>
      </b-btn>
    </b-card>
    <b-card>
      <h4><i class="nav-icon icon-layers"></i> Invoices</h4>
      <hr />
      <div class="row mb-2">
        <div class="col-md-4 mb-2">
          <h5>Select Project To Get Invoices</h5>
          <v-select
            :clearable="false"
            class="project-select"
            v-model="projectid"
            label="projectname"
            :options="projectData"
            @input="loadInvoices()"
          ></v-select>
        </div>
        <div v-if="projectid" class="col-9 col-md-4">
          <h5>Unprocessed / Processed</h5>
          <v-select
            :clearable="false"
            class="project-select"
            v-model="type"
            label="type"
            :options="typeOptions"
            @input="loadInvoices()"
          ></v-select>
        </div>
        <div v-if="projectid" class="col-3 col-md-4">
          <h5>&nbsp;</h5>
          <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <button @click="loadInvoices()" class="btn btn-primary">
              <i class="fa fa-sync-alt"></i>
            </button>
          </b-overlay>
        </div>
      </div>
      <v-client-table
        ref="invoiceTable"
        :columns="columns"
        :data="invoiceData"
        :options="options"
        class="inv-status-table wts-table table-responsive"
        :theme="theme"
        id="dataTable"
      >
        <span slot="total" slot-scope="props">
          {{ formatPrice(props.row.total) }}
        </span>

        <div slot="quickpay_total" slot-scope="props">
          <span
            v-if="
              props.row.quickpay_total > 0 &&
                $moment(props.row.endtday) > $moment()
            "
          >
            {{ formatPrice(props.row.quickpay_total) }}
          </span>
        </div>

        <div slot="inv_passed" slot-scope="props">
          <div v-if="props.row.accountant_paid === 0">
            <div v-if="props.row.inv_passed === 9">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By WSS (Submit Again)
              </button>
            </div>
            <div v-if="props.row.inv_passed === 17">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Accountant
              </button>
            </div>

            <div
              v-if="props.row.inv_passed === 8 && props.row.acc_project != 1"
            >
              <button class="btn-sm btn-warning btn-pm">
                <i class="fa fa-question"></i> Pending By Project Manager
              </button>
            </div>
            <div
              v-if="props.row.inv_passed === 8 && props.row.acc_project == 1"
            >
              <button
                class="btn-sm btn-warning btn-pm"
                style="
                  border-color: #24cabf !important;
                  background: #24cabf !important;
                "
              >
                <i class="fa fa-question"></i> Pending For First Approval
              </button>
            </div>

            <div v-if="props.row.inv_passed === 7">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 6">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By Higher Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 5">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Project Manager (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 0">
              <button class="btn-sm btn-warning">
                <i class="fa fa-question"></i> Pending By WSS
              </button>
            </div>

            <div v-if="props.row.inv_passed === 2">
              <button class="btn-sm btn-blue">
                <i class="fa fa-save"></i> Draft
              </button>
            </div>

            <div v-if="props.row.inv_passed === 11">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 13">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By Higher Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 14">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 21">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 23">
              <button class="btn-sm btn-warning">
                <i class="fa fa-question"></i> Pending By Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 24">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 31">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 33">
              <button class="btn-sm btn-warning">
                <i class="fa fa-question"></i> Pending By Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 34">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 41">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 43">
              <button class="btn-sm btn-warning">
                <i class="fa fa-question"></i> Pending By Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 44">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 51">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 54">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>
          </div>
          <div v-if="props.row.accountant_paid === 1">
            <button class="btn-sm btn-success">
              <i class="fa fa-check"></i> Paid
            </button>
          </div>
        </div>
        <div slot="creation_date" slot-scope="props">
          {{ props.row.creation_date | moment('MM-DD-YYYY') }}
        </div>
        <div slot="invoice_to" slot-scope="props">
          {{
            props.row.invoice_to
              ? lodash
                  .uniq(
                    rtnest_users
                      .filter((x) =>
                        props.row.invoice_to.split(',').includes(x.email)
                      )
                      .map((y) => `${y.fname} ${y.lname}`)
                  )
                  .join(', ')
              : ''
          }}
        </div>

        <div slot="invnum" slot-scope="props">
          <a
            href="javascript:void(0)"
            v-if="
              ($refs.invoiceTable && props.row.inv_passed == 0) ||
                props.row.inv_passed == 8
            "
            @click="setTheInvoice(props.row, 1)"
            >{{ props.row.invnum }}</a
          >
          <a
            href="javascript:void(0)"
            v-else
            @click="setTheInvoice(props.row, 0)"
            >{{ props.row.invnum }}</a
          >
          <p class="mb-0 d-sm-block d-md-none">
            {{ props.row.creation_date | moment('MM-DD-YYYY') }}
          </p>
        </div>

        <div slot="actions" slot-scope="props">
          <button
            v-b-modal.history
            @click="sendHistory(props.row)"
            class="btn white btn-sm btn-secondary"
          >
            <i class="fa fa-history"></i>
          </button>
        </div>
      </v-client-table>

      <b-modal
        ok-only
        id="history"
        ref="history"
        :title="'Invoice #' + history.invnum"
        title-tag="h2"
        centered
        size="xl"
      >
        <InvoiceHistory
          v-bind:history="history"
          v-bind:isMounted="true"
        ></InvoiceHistory>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import { ClientTable } from 'vue-tables-2';
import Datepicker from 'vuejs-datepicker';
import InvoiceHistory from './../invoice/InvoiceHistory';

Vue.use(ClientTable);

export default {
  name: 'Wts',
  components: {
    ClientTable,
    Datepicker,
    InvoiceHistory,
  },
  data() {
    return {
      columns: [
        'inv_passed',
        'invnum',
        'ticketnum',
        'vendor_invoice_number',
        'vcn',
        'invoice_to',
        'creation_date',
        'total',
        'quickpay_total',
        'actions',
      ],
      invoiceData: [],
      invoiceData2: [],
      invoiceTableV: null,
      today: new Date(),
      options: {
        initFilters: {
          GENERIC: this.$session.get('query'),
        },
        orderBy: this.$session.get('orderBy'),
        initialPage: this.$session.get('page'),
        headings: {
          inv_passed: 'Status',
          invnum: 'Invoice #',
          vcn: 'Company Name',
          creation_date: 'Submitted Date',
          vcn: 'Company Name',
          invoice_to: 'Invoice To',
          quickpay_total: 'Quickpay Total',
          ticketnum: 'Field Ticket #',
          vendor_invoice_number: 'Vendor Invoice #',
        },
        perPage: 40,
        oLanguage: {
          sSearch: 'your-text-here',
        },
        sortable: [
          'inv_passed',
          'invnum',
          'vcn',
          'invoice_to',
          'creation_date',
          'total',
          'quickpay_total',
        ],
        filterable: [
          'inv_passed',
          'invnum',
          'ticketnum',
          'vendor_invoice_number',
          'vcn',
          'invoice_to',
          'vlname',
          'vfname',
          'creation_date',
          'total',
          'quickpay_total',
        ],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-up',
          down: 'fa-sort-down',
          is: 'fa-sort',
        },
      },
      searchContainer: false,
      cc: '',
      history: [],
      tracking: '',
      vendor: '',
      type: {},
      typeOptions: [
        {
          type: 'All',
          id: 'All',
        },
        {
          type: 'Processed',
          id: 'processed_invoices',
        },
        {
          type: 'Unprocessed By PM',
          id: 'unprocessed_invoices',
        },
        {
          type: 'Unprocessed By WSS',
          id: 'unprocessed_invoices_wss',
        },
      ],
      projectData: [],
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      projectid: {},
      checkedRows: [],
      afe: '',
      cc1: '',
      cc2: '',
      cc3: '',
      invnum: '',
      date: '',
      vendor_company_name: '',
      projectidSearch: '',
      windowWidth: 0,
      windowHeight: 0,
      wtsId: '',
      rtnest_users: [],
      // wts_list: [],
      isLoading: true,
    };
  },
  watch: {},
  methods: {
    async setTheInvoice(props, setInvoice) {
      let invoiceData = await this.getInvoice(props.invoiceid);
      invoiceData = invoiceData.data.result;
      this.setInvoice(invoiceData, setInvoice, this.$refs.invoiceTable);
      this.$router.push({
        name: 'Project Manager Invoice',
        path: '/pm/invoice',
        params: { invoice: invoiceData, set: setInvoice, role: 'Pm' },
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    async getQbyteInvoices(invoice) {
      const params = {
        invoice: invoice.join(','),
      };

      return this.$http
        .get('/accountant/qbyte/invoices/InovoiceTotal', { params })
        .then((res) => {
          if (res.data.response.length > 0) {
            return res.data.response.reduce((acc, val) => {
              acc[val.invoice_id] = val;
              return acc;
            });
          }
        });
    },
    fiterManager() {
      this.invoiceData.map();
    },
    async loadInvoices() {
      this.$session.set('project', this.projectid);
      this.$session.set('type', this.type);
      try {
        let invType = null;
        let invPassed = null;
        this.isLoading = true;
        const projectid =
          this.projectid.projectid === 0 ? null : this.projectid.projectid;
        if (
          this.type.type === 'Unprocessed By PM' ||
          this.type.type === 'Unprocessed By WSS'
        ) {
          invType = 'unprocessed';
        } else if (this.type.type === 'Processed') {
          invType = 'processed';
        }
        if (this.type.type === 'Unprocessed By PM') {
          invPassed = true;
        } else if (this.type.type === 'Unprocessed By WSS') {
          invPassed = false;
        }
        this.invoiceData = await this.getWtsInvoices(
          invType,
          projectid,
          invPassed
        );
        const invoiceId = this.invoiceData.map((x) => x.invoiceid);
        const qbyteInv = await this.getQbyteInvoices(invoiceId);
        // this.invoiceData = this.invoiceData.filter((val, i) => {
        //   if (val.invoice_to && val.inv_passed !== 0) {
        //   const allowed_emails = val.invoice_to.split(',');
        //   if(this.wts_list.filter(val => allowed_emails.includes(val.email)).length === 0) {
        //       return true;
        //   } else {
        //       return allowed_emails.includes(this.wtsId);
        //    }
        //   } else {
        //     return true;
        //   }
        // });
        this.invoiceData = this.invoiceData.map((inv) => {
          if (qbyteInv[inv.invoiceid] && qbyteInv[inv.invoiceid].total) {
            return {
              ...inv,
              total: qbyteInv[inv.invoiceid].total,
              quickpay_total: qbyteInv[inv.invoiceid].quickpay_total,
            };
          }
          return inv;
        });
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(':::', err);
      }
    },
    async getWtsInvoices(invType, project, invPassed) {
      const params = {};
      if (invType) {
        params.invType = invType;
      }
      if (project) {
        params.project = project;
      }
      return this.$http.get('/wts/get/inv', { params }).then((res) =>
        res.data.result
          .map((val) => {
            return {
              ...val,
              ...(val.creation_date && {
                creation_date: val.creation_date,
              }),
            };
          })
          .reverse()
          .filter((x) => {
            if (invPassed === null) {
              return true;
            }
            if (invPassed === false) {
              return x.inv_passed == 0;
            }

            if (invPassed === true) {
              return x.inv_passed != 0;
            }
          })
      );
    },
    async getInvoices(type, id) {
      if (id > 0) {
        if (type == 'unprocessed_invoices' || type == 'processed_invoices') {
          return this.$axios
            .get('/wts/get/' + type + '/' + id)
            .then((response) => {
              if (this.type.type === 'Unprocessed By WSS') {
                return response.data.result
                  .filter((x) => x.inv_passed == 0)
                  .reverse();
              } else {
                return response.data.result
                  .filter((x) => x.inv_passed != 0)
                  .reverse();
              }
            })
            .catch((error) => {});
        } else {
          const unprocessed_invoices = await this.$axios
            .get('/wts/get/processed_invoices/' + id)
            .then((response) => {
              if (this.type.type === 'Unprocessed By WSS') {
                return response.data.result
                  .filter((x) => x.inv_passed == 0)
                  .reverse();
              } else {
                return response.data.result;
              }
            })
            .catch((error) => {});

          const processed_invoices = await this.$axios
            .get('/wts/get/unprocessed_invoices/' + id)
            .then((response) => {
              if (this.type.type === 'Unprocessed By WSS') {
                return response.data.result
                  .filter((x) => x.inv_passed == 0)
                  .reverse();
              } else {
                return response.data.result;
              }
            })
            .catch((error) => {});

          if (
            unprocessed_invoices !== undefined &&
            processed_invoices !== undefined
          ) {
            const combined_invoices = await this.combineInvoices(
              unprocessed_invoices,
              processed_invoices
            );
            return combined_invoices;
          }
        }
      }
    },
    combineInvoices(un, pro) {
      return un.concat(pro);
    },
    search() {
      if (this.date !== '') {
        this.date = this.$moment(this.date).format('YYYY-MM-DD');
      }
      const selected_projects =
        this.projectidSearch === 0
          ? this.projectData.map((val) => val.projectid)
          : [this.projectidSearch];
      let data = JSON.stringify({
        afe: this.afe,
        cc1: this.cc1,
        cc2: this.cc2,
        cc3: this.cc3,
        invnum: this.invnum,
        date: this.date,
        vendor_company_name: this.vendor_company_name,
        projectid: selected_projects,
      });
      this.$axios
        .post('/wts/search/invoice', data)
        .then((response) => {
          var invoiceData = this.lodash.uniqBy(response.data.result, 'invnum');
          this.loadSearchInvoices(invoiceData);
        })
        .catch((error) => {
        this.$toasted.show('Error Applying Custome Search Fields.', {
          type: 'error',
          duration: '3000',
        });
        });
    },
    loadSearchInvoices(invoiceData) {
      this.invoiceData = [];
      const params = {};
      params.invoiceid = invoiceData.map((val) => val.invoiceid).join(',');
      this.$axios
        .get('/wts/get/invoice/invNum', { params })
        .then((response) => {
          this.error = true;
          this.invoiceData = response.data.result;
        })
        .catch((error) => {
        this.$toasted.show('Error Fetching Search Invoices.', {
          type: 'error',
          duration: '3000',
        });
        });
    },
    sendHistory(history) {
      this.history = history;
    },
    clear() {
      this.afe = '';
      this.cc1 = '';
      this.cc2 = '';
      this.cc3 = '';
      this.invnum = '';
      this.date = '';
      this.vendor_company_name = '';
    },
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth < 991) {
        this.searchContainer = false;
      }
      if (this.windowWidth > 991) {
        this.searchContainer = false;
      }
    },
    getWindowHeight(event) {
      this.windowHeight = document.documentElement.clientHeight;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
    window.removeEventListener('resize', this.getWindowHeight);
  },
  mounted() {
    this.$http
      .get('/wts/get/profile')
      .then((response) => {
        this.wtsId = response.data.email;
      })
      .catch((error) => {});
    this.$http
      .get('/wts/get/profile/rtnest_users')
      .then((response) => {
        this.rtnest_users = [
          ...response.data.wts_result,
          ...response.data.wss_result,
        ];
        // this.wts_list = response.data.wts_result;
      })
      .catch((error) => {});

    this.$axios
      .get('wts/get/projects')
      .then((response) => {
        this.projectData = response.data.result;
        this.projectData.push({ projectid: 0, projectname: 'All' });

        if (response.data.result.length > 0) {
          this.projectidSearch = response.data.result[0].projectid;
        }

        if (this.$session.get('project') && this.$session.get('type')) {
          this.projectid = this.$session.get('project');
          this.type = this.$session.get('type');
          this.loadInvoices();
        } else {
          this.projectid = { projectid: 0, projectname: 'All' };
          this.type = { type: 'Unprocessed By PM', id: 'unprocessed_invoices' };
          this.loadInvoices();
        }
      })
      .catch((error) => {});
    this.$nextTick(function() {
      window.addEventListener('resize', this.getWindowWidth);
      window.addEventListener('resize', this.getWindowHeight);

      //Init
      this.getWindowWidth();
      this.getWindowHeight();
    });
  },
};
</script>
